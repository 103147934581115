<template>
  <vue100vh
    :css="{ height: '100rvh' }"
    class="relative gap-4 md:gap-6 grid grid-rows-1/auto px-3 pt-3 pb-4 md:p-6 w-full select-none"
    :draggable="false"
  >
    <div class="flex h-full w-full items-center justify-center">
      <div>
        <h1 class="text-6xl">That's all.</h1>
      </div>
    </div>
    <div>
      <button
        type="button"
        class="inline-block bg-white text-gray-900 font-bold px-6 py-3 text-xl rounded-md border-4 border-white m-4 focus:outline-none appearance-none focus:shadow-outline"
        @click="onStartOver"
      >
        Return to homepage
      </button>
    </div>
  </vue100vh>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import vue100vh from 'vue-100vh-vp'

export default {
  name: 'Finished',
  components: {
    vue100vh,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['quizFinished']),
  },
  methods: {
    ...mapActions(['resetQuiz']),
    onStartOver() {
      this.resetQuiz().then(() => {
        this.$router.push({ path: '/' })
      })
    },
  },
  created() {
    if (!this.quizFinished) {
      this.$router.push({ path: '/' })
      return
    }
  },
}
</script>

<style lang="postcss"></style>
